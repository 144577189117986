<template>
  <div class="detail">
    <GlobalInfoBar :title="title" />
    <GlobalChunk icon="order" title="订单信息" :padding="[20, 20, 20, 20]">
      <el-form ref="form" :model="form" label-width="160px">
        <template v-for="(item, index) in formItems">
          <el-form-item
            :label="item.label"
            :key="item.prop"
            v-if="!isAssociatedDisplay(item)"
            v-show="
              !item.isNullValueHiding ||
                (item.isNullValueHiding && form[item.prop])
            "
          >
            <el-button
              type="text"
              @click="viewOrder('register')"
              v-if="item.prop === 'viewOrder'"
              >查看注册订单</el-button
            >
            <span
              v-if="
                item.label == '注册订单' &&
                  idFlag &&
                  formData &&
                  formData.order &&
                  formData.order.two == '1'
              "
              style="margin-left: 20px; color: red"
              >同购</span
            >
            <span
              style="margin-left: 20px; color: red"
              v-if="
                item.label == '注册订单' &&
                  formData.order &&
                  formData.order.is_rong === 1
              "
              >蓉家保</span
            >
            <!--输入框-->
            <el-input
              v-if="item.type === 'input'"
              v-model="form[item.prop]"
              :placeholder="'请输入' + item.label"
              :disabled="item.disabled"
            ></el-input>

            <el-button
              type="primary"
              plain
              v-if="orderType === 'register' && item.prop === 'imei'"
              style="margin-left: 30px"
              @click="queryActiveTime"
              >查询激活时间
            </el-button>
            <el-button
              type="primary"
              plain
              v-if="orderType === 'register' && item.prop === 'push_imei'"
              style="margin-left: 30px"
              @click="pushImeiEdit"
              >修改
            </el-button>

            <div
              style="
                  font-size: 14px;
                  color: #666;
                  line-height: 24px;
                  margin-top: 10px;
                "
              v-if="
                orderType === 'register' && item.prop === 'imei' && imeiData
              "
            >
              <div>
                <span
                  style="
                      width: 4em;
                      display: inline-block;
                      text-align-last: justify;
                      color: #999;
                    "
                  >保修</span
                >：{{ imeiData.guarantee || "--" }}
              </div>
              <div>
                <span
                  style="
                      width: 4em;
                      display: inline-block;
                      text-align-last: justify;
                      color: #999;
                    "
                  >机型信息</span
                >：{{ imeiData.phone || "--" }}
              </div>
              <div>
                <span
                  style="
                      width: 4em;
                      display: inline-block;
                      text-align-last: justify;
                      color: #999;
                    "
                  >激活时间</span
                >：{{ imeiData.time || "--" }}
              </div>
            </div>

            <!--选择框-->
            <el-select
              v-if="
                item.type === 'select' &&
                  item.optValue &&
                  item.optLabel &&
                  !item.multiple
              "
              v-model="form[item.prop]"
              :placeholder="'请选择' + item.label"
              filterable
              :clearable="item.clearable"
              :disabled="item.disabled"
              @change="selectChange(item.relate)"
            >
              <el-option
                v-for="opt in getOptions(item.optListName)"
                :key="opt[item.optValue]"
                :label="opt[item.optLabel]"
                :value="opt[item.optValue]"
              >
              </el-option>
            </el-select>

            <!--可多选-->
            <el-select
              v-if="
                item.type === 'select' &&
                  item.optValue &&
                  item.optLabel &&
                  item.multiple
              "
              :multiple="true"
              filterable
              :clearable="item.clearable"
              v-model="form[item.prop]"
              :placeholder="'请选择' + item.label"
              :disabled="item.disabled"
              @change="selectChange(item.relate)"
            >
              <el-option
                v-for="opt in getOptions(item.optListName)"
                :key="opt[item.optValue]"
                :label="opt[item.optLabel]"
                :value="opt[item.optValue]"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="item.type === 'select' && !item.optValue && !item.optLabel"
              v-model="form[item.prop]"
              :placeholder="'请选择' + item.label"
              filterable
              :clearable="item.clearable"
              :disabled="item.disabled"
              @change="selectChange(item.relate)"
            >
              <el-option
                v-for="opt in getOptions(item.optListName)"
                :key="opt"
                :label="opt"
                :value="opt"
              >
              </el-option>
            </el-select>

            <!--日期框-->
            <el-date-picker
              v-if="item.type === 'date'"
              v-model="form[item.prop]"
              align="right"
              type="date"
              :placeholder="'请选择' + item.label"
              value-format="yyyy-MM-dd"
              :disabled="item.disabled"
            >
            </el-date-picker>

            <!--dateRange -->
            <el-date-picker
              v-if="item.type === 'dateRange'"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              v-model="temp[item.tempProp]"
              :value="[form[item.props[0]], form[item.props[1]]]"
              @change="
                (range) => {
                  dateRangeChange(range, item);
                }
              "
            >
            </el-date-picker>

            <!--textarea-->
            <el-input
              v-if="item.type === 'textarea'"
              v-model="form[item.prop]"
              type="textarea"
              :placeholder="'请填写' + item.label"
              :disabled="item.disabled"
            ></el-input>

            <!--radio-->
            <el-radio-group
              v-if="item.type === 'radio' && item.options"
              v-model="form[item.prop]"
              @change="selectChange(item.relate)"
            >
              <el-radio
                v-for="opt in item.options"
                :key="opt.name"
                :label="opt.label"
                :disabled="item.disabled"
                >{{ opt.name }}
              </el-radio>
            </el-radio-group>

            <el-radio-group
              v-if="item.type === 'radio' && item.optListName"
              v-model="form[item.prop]"
              @change="selectChange(item.relate, item.hx)"
            >
              <el-radio
                style="margin-bottom: 10px"
                v-for="opt in getOptions(item.optListName)"
                :key="opt[item.optLabel]"
                :label="opt[item.optValue]"
                >{{ opt[item.optLabel] }}
              </el-radio>
            </el-radio-group>

            <!--img-->
            <template v-if="item.type === 'img'">
              <!--          order.tem_img-->
              <div>（点击图片查看大图）</div>

              <div style="display: flex">
                <el-image
                  v-if="form[item.prop]"
                  class="preview-img"
                  style="margin-right: 20px; margin-bottom: 20px"
                  :src="form[item.prop]"
                  :preview-src-list="[form[item.prop]]"
                  :data-watermark="!!item.watermark"
                  @click.stop="closePreview"
                >
                </el-image>
                <div
                  class="original-pic"
                  v-if="
                    formData[item.obj] &&
                      formData[item.obj]['old' + item.prop.replace('tem', '')]
                  "
                >
                  <el-image
                    class="preview-img"
                    :src="
                      formData[item.obj] &&
                        formData[item.obj]['old' + item.prop.replace('tem', '')]
                    "
                    :preview-src-list="[
                      formData[item.obj] &&
                        formData[item.obj][
                          'old' + item.prop.replace('tem', '')
                        ],
                    ]"
                    :data-watermark="!!item.watermark"
                    @click.stop="closePreview"
                  >
                  </el-image>
                  <div class="mark">原图</div>
                </div>

                <el-upload
                  :ref="item.prop"
                  v-if="!form[item.prop]"
                  action="#"
                  list-type="picture-card"
                  :limit="1"
                  show-file-list
                  :auto-upload="false"
                  :on-change="
                    (file) => {
                      getFile(file, item.prop);
                    }
                  "
                >
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </div>

              <div class="img-operation">
                <el-button
                  plain
                  size="mini"
                  @click="uploadImg(item.prop)"
                  v-if="form[item.prop]"
                  >编辑
                </el-button>

                <template>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="saveOriginalImage(item.prop, item.obj)"
                    >保留原图</el-button
                  >
                  <div class="notice">
                    最多支持上传1张图片，大小不超过5M，格式为JPG或PNG
                  </div>
                </template>
              </div>
            </template>

            <!--img-->
            <template v-if="item.type === 'img2'">
              <!--          order.tem_img-->
              <!-- <div>（点击图片查看大图）</div> -->

              <div style="display: flex">
                <el-image
                  v-if="form[item.prop]"
                  class="preview-img"
                  style="margin-right: 20px; margin-bottom: 20px"
                  :src="form[item.prop]"
                  :preview-src-list="[form[item.prop]]"
                  :data-watermark="!!item.watermark"
                  @click.stop="closePreview"
                >
                </el-image>

                <el-upload
                  :ref="item.prop"
                  v-if="!form[item.prop]"
                  action="#"
                  list-type="picture-card"
                  :limit="1"
                  show-file-list
                  :auto-upload="false"
                  :on-change="
                    (file) => {
                      getFile(file, item.prop);
                    }
                  "
                >
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </div>

              <div class="img-operation">
                <el-button
                  type="primary"
                  size="mini"
                  @click="uploadImg(item.prop)"
                  v-if="form[item.prop]"
                  >重新上传
                </el-button>
              </div>
            </template>
            <!-- img_add -->
            <template v-if="item.type === 'img_add'">
              <div>（点击图片查看大图）</div>
              <div style="display: flex" v-if="form[item.prop].length">
                <div v-for="(el, index) in form[item.prop]" :key="index">
                  <!-- <el-image class="preview-img" style="margin-right: 20px; margin-bottom: 20px" :src="el"
                    :preview-src-list="form[item.prop]">
                  </el-image> -->
                  <changePic v-model="form[item.prop][index]"></changePic>
                </div>
              </div>
            </template>

            <!--预警-->
            <template v-if="item.type === 'warning'">
              <span class="warning"
                >距离注册：<span>{{ form[item.props[0]] }}</span
                >天，手机号：<span>{{ form[item.props[1]] }}</span
                >次，身份证：<span>{{ form[item.props[2]] }}</span
                >次,IMEI码理赔次数：<span>{{ form[item.props[3]] }}</span
                >次</span
              >
            </template>
            <!-- 权益选择--文字展示 -->
            <template v-if="item.type === 'tex'">
              <span class="warning">
                {{ form[item.prop] || "--" }}
              </span>
              <span
                class="rong"
                v-if="
                  item.prop === 'rong_address' &&
                    formData.order &&
                    formData.order.is_rong === 1
                "
                >已申请蓉家保</span
              >
            </template>
            <!--视频-->
            <video
              :src="form[item.prop]"
              controls="controls"
              v-if="item.type === 'video' && form[item.prop]"
              style="height: 400px"
            ></video>

            <photo-resubmission-order-list
              v-model="form.relation_id"
              v-if="item.type === 'photoResubmissionOrderList'"
              :table-data="formData.order && formData.order.order_list"
            ></photo-resubmission-order-list>
            <!--         append   -->
            <span class="append" v-if="item.append === 'viewOrder'">
              <el-button type="text" @click="viewOrder('register')"
                >查看注册订单</el-button
              >
              <el-button
                type="text"
                v-if="type === '13'"
                @click="viewOrder('claim')"
                >查看理赔订单</el-button
              >
            </span>

            <el-tag
              class="append"
              :type="
                ['success', 'danger'][Number(formData.order.link_send) - 1]
              "
              v-if="
                item.append === 'link_send' &&
                  formData.order &&
                  formData.order.link_send
              "
            >
              {{
                ["发送成功！", "发送失败！"][
                  Number(formData.order.link_send) - 1
                ]
              }}
            </el-tag>

            <el-tag
              type="warning"
              class="append"
              v-if="
                item.append === 'pushXi' &&
                  formData.order &&
                  formData.order.pushXi
              "
              >{{ formData.order.pushXi }}
            </el-tag>
            <span
              style="margin-left: 10px;"
              v-if="item.label == '手机型号' && queryDetail"
              >{{ queryDetail }}</span
            >
            <div v-if="item.type == 'wxpay'">
              <template v-if="formData.order && formData.order.is_wxpay">
                <div>
                  <el-link
                    v-if="formData.order.is_refund == 1"
                    type="danger"
                    :underline="false"
                    >已退款</el-link
                  >
                  <el-link
                    v-else-if="formData.order.is_refund == 2"
                    type="danger"
                    :underline="false"
                    >退款失败</el-link
                  >
                  <el-link v-else type="success" :underline="false"
                    >已付款</el-link
                  >
                  <el-button
                    v-if="formData.order.is_refund != 1"
                    type="warning"
                    size="mini"
                    style="margin-left: 20px"
                    @click="refund"
                    >退款</el-button
                  >
                </div>
              </template>
              <template v-else>无</template>
            </div>
            <el-link
              v-if="item.prop == 'imei' && item.isNew"
              type="danger"
              :underline="false"
              style="margin-left: 20px;"
              >{{ formData.order.new_imei_num }}</el-link
            >
            <span
              v-if="item.prop === 'ram_size' && machineType == 2"
              :key="item.prop"
              style="margin-left: 10px;"
              >官网价:{{ official_price }}元</span
            ><span
              v-if="item.prop === 'ram_size' && machineType == 2"
              :key="index"
              style="margin-left: 10px;"
              >协议价:{{ price }}元</span
            >
          </el-form-item>
        </template>

        <div class="bottom-btns">
          <el-button plain @click="reset">重置</el-button>
          <el-button type="primary" :loading="submitShow" @click="confirm"
            >确定</el-button
          >
        </div>
      </el-form>
    </GlobalChunk>

    <GlobalChunk
      style="margin-top: 20px"
      icon="order"
      title="注册信息"
      :padding="[20, 20, 20, 20]"
    >
      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns"
        :data="[formData.member || {}]"
        :isPagination="false"
      >
        <el-table-column label="微信头像" slot="headimg" align="center">
          <template slot-scope="{ row }">
            <el-image
              class="head-img"
              :src="row.headimg"
              :preview-src-list="[row.headimg]"
              @click.stop="closePreview"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button
              type="success"
              @click="sendMessageToWX(row)"
              size="mini"
              style="border-radius: 50px"
              >微信发送消息
            </el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <GlobalChunk
      hidden
      style="margin-top: 20px"
      icon="order"
      title="人保回调"
      :padding="[20, 20, 20, 20]"
    >
      <div class="picc-callback" v-if="piccCallbackType === 1">
        <div class="item">
          <div class="name">UUD：</div>
          <div class="value"></div>
        </div>
        <div class="item">
          <div class="name">返回信息：</div>
          <div class="value"></div>
        </div>
        <div class="item">
          <div class="name">insureid：</div>
          <div class="value"></div>
        </div>
        <div class="item">
          <div class="name">创建时间：</div>
          <div class="value"></div>
        </div>
        <div class="item">
          <div class="name">保单号：</div>
          <div class="value"></div>
        </div>
        <div class="item">
          <div class="name">回调时间：</div>
          <div class="value"></div>
        </div>
        <div class="item">
          <div class="name">返回码：</div>
          <div class="value"></div>
        </div>
        <div class="item">
          <div class="name">人保推送：</div>
          <div class="value">
            <el-button size="mini" type="primary">点击推送</el-button>
          </div>
        </div>
      </div>
      <!--todo-->
      <el-button v-if="piccCallbackType === 2" type="primary">提交</el-button>
    </GlobalChunk>

    <div
      class="other-info"
      :style="{
        marginLeft: formItems[0] && formItems[0].append ? '200px' : '',
      }"
    >
      <div class="sub-insurance" v-if="formData.son && formData.son.length">
        <div class="title">
          注册子保险信息
          <span class="total-cost" v-if="formData.order.cost_price"
            >合计成本：￥{{ formData.order.cost_price }}</span
          >
        </div>
        <div v-for="(item, index) in formData.son" :key="index">
          <div style="margin: 10px 0; white-space: nowrap">
            {{ item.son_name }}（{{ item.years }}）<span
              v-if="item.select"
              style="font-weight: bold"
              >【理赔选择】</span
            >
          </div>
          <div style="font-size: 14px; color: #999">
            <span style="margin-right: 20px" v-if="item.type === 2"
              >价位段：{{ item.min_price }}-{{ item.max_price }}</span
            >
            <span style="margin-right: 20px" v-if="item.type === 1"
              >是否是Pro版本：{{ item.is_pro ? "是" : "否" }}</span
            >
            <span>成本：￥{{ item.cost_price }}</span>
          </div>
        </div>
      </div>
      <div class="sub-insurance" v-if="formData.order && formData.order.choose">
        <div class="title">附加保险</div>
        <div style="margin: 10px 0; white-space: nowrap">
          <span>{{ formData.order.vice_card_name }}</span>
        </div>
        <div style="font-size: 14px; color: #999">
          <span
            style="margin-right: 20px"
            v-if="formData.order.vice_card_secret"
            >卡密：{{ formData.order.vice_card_secret }}</span
          >
          <span style="margin-right: 20px" v-if="formData.order.vice_card_num"
            >卡号：{{ formData.order.vice_card_num }}</span
          >
        </div>
      </div>
      <div
        class="sub-insurance"
        v-if="formData.order && formData.order.two && formData.twoOrder"
      >
        <div class="title">同购保险</div>
        <div v-for="item in formData.twoOrder" :key="item.order_id">
          <div style="margin: 10px 0; white-space: nowrap">
            <span>{{ item.card_name }}</span>
          </div>
          <div style="font-size: 14px; color: #999">
            <span style="margin-right: 20px" v-if="item.card_secret"
              >卡密：{{ item.card_secret }}</span
            >
            <span style="margin-right: 20px" v-if="item.card_num"
              >卡号：{{ item.card_num }}</span
            >
          </div>
          <a
            :href="`/order/detail/${item.order_mold}/${item.order_id}`"
            target="_blank"
            style="font-size: 14px; display: inline-block; margin-top: 10px"
            >{{ item.order_sn }}</a
          >
        </div>
      </div>

      <div
        class="photo-resubmission"
        v-if="formData.order && formData.order.make_img"
      >
        <div class="title" style="margin-bottom: 10px">照片补交</div>
        <el-image
          class="preview-img"
          :src="formData.order.make_img"
          :preview-src-list="[formData.order.make_img]"
          @click.stop="closePreview"
        >
        </el-image>
        <p>
          <a
            :href="`/order/detail/21/${formData.order.make_id}`"
            target="_blank"
            style="width: 148px; text-align: center; display: inline-block"
            >查看补交订单</a
          >
        </p>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="400px">
      <span>{{ dialogText }}</span>
      <span slot="footer">
        <el-button type="primary" @click="goback()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/utils/request";
import { wxRefund } from "@/utils/request/partnerManage";
import dateFormat from "@/utils";
import PhotoResubmissionOrderList from "./components/photoResubmissionOrderList";
// import { Switch } from "element-ui";
import changePic from "./components/changePic.vue";

export default {
  name: "index",
  components: { PhotoResubmissionOrderList, changePic },
  props: {
    type: [String, Number],
    orderId: [String, Number],
  },
  data() {
    return {
      cardIddata: "",
      submitShow: false,
      centerDialogVisible: false,
      dialogText: "",
      //处理前的数据
      formData: {},
      //表单项配置
      formItems: [],
      form: {},
      title: "",
      orderType: "",

      brandList: [],
      modelList: [],
      gearList: [],
      RAMList: [],
      IDcard: [
        { name: "大陆身份证", status: 1 },
        { name: "港澳天身份证", status: 2 },
      ],
      netStandardList: [],
      phoneStatusList: [],
      phoneScreenStatusList: [],
      serviceList: [],
      serviceScreenList: [
        "免费换屏幕",
        "免费换后盖",
        "免费换电池",
        "质量问题换机",
      ],
      serviceScreenList2: [
        "免费换屏幕",
        "免费换后盖",
        "免费换电池",
        "免费延保",
      ],
      serviceScreenList3: [
        "免费换屏幕",
        "免费换后盖",
        "免费换电池",
        "免费延保",
        "折抵换机",
        "进水保修",
      ],
      maintainList: ["在购机门店维修", "本地维修点维修", "邮寄至成都总部维修"],
      statusList: [],
      personalReimbursementList: [
        { name: "是", value: 1 },
        { name: "否", value: 0 },
      ],
      changeMethodList: [],
      changeIMEIReasonList: [],
      changeIMEITypeList: [],
      repairTypeList: [],
      idFlag: false,
      repairMethodList: [],
      iphoneServeList: [],
      upServerList: [],
      androidServeList: [],
      iphoneNnhxServeList: [],
      androidNnhxServeList: [],
      isUpgradeList: ["同代同型换新机", "迭代升级换新机"],
      photoResubmissionTypeList: [],
      logisticsList: [],
      orderTypeList: [
        { id: "01", name: "个人" },
        { id: "31", name: "商家" },
      ],
      chooseList: [
        { id: 1, name: "是" },
        { id: 0, name: "否" },
      ],

      tableColumns: [],
      tableData: [],
      piccCallbackType: "",
      temp: {},
      imeiData: null,
      queryDetail: "",
      official_price: "", //官网价
      price: "", //协议价
      machineType: 1, //1 ios,2安卓
    };
  },
  computed: {
    getOptions() {
      return (optsName) => {
        return this[optsName];
      };
    },
    isAssociatedDisplay() {
      return (item) => {
        //特殊先处理
        if (item.associatedDisplay === "is_up") {
          let flag = item.showValueList.indexOf(this.form.is_up) === -1;
          if (flag) {
            this.form.new_model = "";
            this.form.new_ram = "";
          }
          return flag;
        }

        if (item.showValueList) {
          let flag =
            item.showValueList.indexOf(this.form[item.associatedDisplay]) ===
            -1;
          //如果隐藏，把值清空
          if (flag) {
            this.form[item.prop] = "";
          }
          return flag;
        }
        if (item.hideValueList) {
          return (
            item.hideValueList.indexOf(this.form[item.associatedDisplay]) !== -1
          );
        }
        return false;
      };
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    },
  },
  methods: {
    // push_imei修改
    pushImeiEdit() {
      console.log(this.form.push_imei, "22222222");
      console.log("111111111");
      api
        .changeOrderImei({
          order_id: this.formData.order.order_id,
          push_imei: this.form.push_imei,
        })
        .then((res) => {
          this.$message.success((res && res.msg) || "修改成功");
        });
    },
    init() {
      this.getOrder();
      //获取配置
      let currentForm = require(`./orderType${this.type}.js`);

      this.formItems = currentForm.form;
      this.idFlag = currentForm.idFlag;
      this.tableColumns = currentForm.tableColumns;
      this.piccCallbackType = currentForm.piccCallbackType;
      this.orderType = currentForm.type || "";
      let title = currentForm.name + "订单详情";
      this.title = title;
      window.document.title = title;
    },
    getOrder() {
      api.orderInfo({ mold: this.type, order_id: this.orderId }).then((res) => {
        if (res.code == 233) {
          this.dialogText = res.msg;
          this.centerDialogVisible = true;
        } else {
          console.log(res);
          this.cardIddata = res.data||{};
          this.queryDetail = res.data.query;
          let formData = res.data || {};
          this.formData = formData;
          this.official_price = this.formData.phonePrice?.official_price || "";
          this.price = this.formData.phonePrice?.price || "";
          this.machineType = this.formData.order.type;
          let form = {};
          this.formItems.forEach((item) => {
            if (formData[item.obj] && item.prop) {
              form[item.prop] = formData[item.obj][item.prop];
              //临时prop，比如时间范围选择需要起始时间两个属性生成一个临时属性
            } else if (item.tempProp) {
              let tempProp = "";
              if (
                item.tempProp === "dateRange_term_time" ||
                item.tempProp === "dateRange_term_time2"
              ) {
                tempProp = [
                  formData[item.obj][item.props[0]] || "",
                  formData[item.obj][item.props[1]] || "",
                ];
              }
              this.$set(this.temp, item.tempProp, tempProp);
              item.props.forEach((p) => {
                form[p] = formData[item.obj][p];
              });
            }
            //通过optListName获取数组
            if (item.optListName) {
              this.getListByName(item.optListName);
            }
          });
          this.form = form;
          this.gearList = formData.order.select_gear;

          if (res.data.options) {
            let statusList = [];
            Object.keys(res.data.options).forEach((key) => {
              let isNumberKey = new RegExp(/^\d+$/).test(key);
              statusList.push({
                status: isNumberKey ? parseInt(key) : key,
                name: res.data.options[key],
              });
            });
            this.statusList = statusList;
          }
        }
      });
    },
    goback() {
      this.$router.back();
    },
    getListByName(name) {
      if (typeof name !== "string") {
        return;
      }
      //拼接获取数组的方法
      let functionName =
        "get" + name.replace(/^[a-z]/, (initial) => initial.toUpperCase());
      if (typeof this[functionName] === "function") {
        this[functionName]();
      }
    },
    uploadImg(prop) {
      this.form[prop] = "";
    },
    getFile(file, prop) {
      let formData = new FormData();
      formData.append("file", file.raw);
      // 上传图片
      api.imageUp(formData).then((res) => {
        this.form[prop] = res.data.url;
        // 工单--图片上传回来后，调用接口直接改订单图片
        // console.log(this.form,'22222哈哈哈哈');
        // console.log(this.orderId,'33333哈哈哈哈');
        // console.log(prop,'哈哈哈哈');
        if (prop == "tem_work") {
          api
            .orderImgUp({
              id: this.orderId,
              url: res.data.url,
              name: "tem_work",
              type: this.type == 13 ? 2 : 1,
            })
            .then((res) => {
              this.$message.success(res.msg || "已更新图片");
            });
        }
        if (prop == "tem_work2") {
          api
            .orderImgUp({
              id: this.orderId,
              url: res.data.url,
              name: "tem_work2",
              type: this.type == 13 ? 2 : 1,
            })
            .then((res) => {
              this.$message.success(res.msg || "已更新图片");
            });
        }
        if (prop == "tem_work3") {
          api
            .orderImgUp({
              id: this.orderId,
              url: res.data.url,
              name: "tem_work3",
              type: this.type == 13 ? 2 : 1,
            })
            .then((res) => {
              this.$message.success(res.msg || "已更新图片");
            });
        }
        //  if (prop == 'tem_img'&&this.type == 13) {
        //   api.orderImgUp({ id: this.orderId, url: res.data.url, name: 'tem_img', type: 2 }).then(res => {
        //     this.$message.success(res.msg || "已更新图片");
        //   })
        // }
        // if (prop == 'tem_img'&&(this.type == 37||this.type == 44||this.type == 32||this.type == 16)) {
        //   api.orderImgUp({ id: this.orderId, url: res.data.url, name: 'tem_img', type: 1 }).then(res => {
        //     this.$message.success(res.msg || "已更新图片");
        //   })
        // }
        // if (prop == 'tem_img'&&!(this.type == 37||this.type == 44||this.type == 32||this.type == 16||this.type == 13||this.type == 14||this.type == 21)) {
        //   api.orderImgUp({ id: this.orderId, url: res.data.url, name: 'tem_img', type: 4 }).then(res => {
        //     this.$message.success(res.msg || "已更新图片");
        //   })
        // }

        if (prop == "tem_card") {
          api
            .orderImgUp({
              id: this.orderId,
              url: res.data.url,
              name: "tem_card",
              type: 4,
            })
            .then((res) => {
              this.$message.success(res.msg || "已更新图片");
            });
        }
        if (prop == "tem_back") {
          api
            .orderImgUp({
              id: this.orderId,
              url: res.data.url,
              name: "tem_back",
              type: 4,
            })
            .then((res) => {
              this.$message.success(res.msg || "已更新图片");
            });
        }
        if (prop == "tem_receive") {
          api
            .orderImgUp({
              id: this.orderId,
              url: res.data.url,
              name: "tem_receive",
              type: 4,
            })
            .then((res) => {
              this.$message.success(res.msg || "已更新图片");
            });
        }
      });
    },
    closePreview(event) {
      // 获取遮罩层dom
      setTimeout(() => {
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if (
            e.target.parentNode.className ===
              "el-image-viewer__actions__inner" ||
            e.target.parentNode.className === "el-image-viewer__canvas"
          ) {
            return; //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });

        //添加水印
        if (event.target.dataset.watermark) {
          let div = document.createElement("div");
          let text = "";
          if (this.form.card_secret) {
            text += `<p>卡密：${this.form.card_secret}</p>`;
          }
          if (this.form.card_num) {
            text += `<p>卡号：${this.form.card_num}</p>`;
          }
          if (this.form.imei) {
            text += `<p>IMEI码：${this.form.imei}</p>`;
          }
          if (this.form.card_name) {
            text += `<p>销售商家：${this.form.card_name}</p>`;
          }
          div.innerHTML = text;
          div.classList = ["watermark"];
          domImageMask.appendChild(div);
        }
      }, 300);
    },
    getBrandList() {
      api.phoneBrandList().then((res) => {
        this.brandList = res.data || [];
        this.getModelList(this.form.brand_id);
      });
    },
    getModelList(brand_id) {
      api.pullModelList({ brand_id }).then((res) => {
        this.modelList = res.data || [];
      });
    },
    getRAMList() {
      api.phoneRam().then((res) => {
        let list = res.data || [];
        this.RAMList = list.map((item) => item.ram_size);
      });
    },
    getNetStandardList() {
      api.pullNetList().then((res) => {
        let list = res.data;
        this.netStandardList = list.map((item) => item.net_name);
      });
    },
    getChangeMethodList() {
      api.insServiceAn().then((res) => {
        this.changeMethodList = res.data || [];
      });
    },
    getChangeIMEIReasonList() {
      api.replaceType().then((res) => {
        this.changeIMEIReasonList = res.data || [];
      });
    },
    getPhoneStatusList() {
      api.damageConfig().then((res) => {
        this.phoneStatusList = res.data || [];
      });
    },
    getChangeIMEITypeList() {
      api.pullInsTypeList().then((res) => {
        this.changeIMEITypeList = res.data || [];
      });
    },
    getServiceList() {
      api
        .insService({ instype_id: this.formData.order.instype_id })
        .then((res) => {
          this.serviceList = res.data || [];
        });
    },
    getRepairTypeList() {
      api.getFinishType().then((res) => {
        this.repairTypeList = res.data;
      });
    },
    getRepairMethodList() {
      api.getFinishMode().then((res) => {
        this.repairMethodList = res.data;
      });
    },
    getPhoneScreenStatusList() {
      api.damageConfig2().then((res) => {
        let list = res.data || [];
        this.phoneScreenStatusList = list;
        console.log(this.formItems, "888888888");
        console.log(this.formData, "7777777777");
        console.log(this.form, "6666666666");
        // this.form
        let temp = this.formData.order.damage_title
          ? this.formData.order.damage_title.split(",")
          : [];
        console.log("11111111111111", temp);
        let selectedList = [];
        temp.forEach((item) => {
          let findResult = list.find((i) => {
            return i.damage_title === item;
          });
          if (findResult) {
            selectedList.push(findResult.damage_id);
          }
        });
        this.form.damage_title = selectedList;
        console.log(this.form, "55556666666666");
      });
    },
    getIphoneNnhxServeList() {
      api.nnhxService({ type: 1 }).then((res) => {
        this.iphoneNnhxServeList = res.data;
      });
    },
    getAndroidNnhxServeList() {
      api.nnhxService({ type: 2 }).then((res) => {
        this.androidNnhxServeList = res.data;
      });
    },
    getIphoneServeList() {
      api.insService({ instype_id: 3 }).then((res) => {
        this.iphoneServeList = res.data;
      });
    },
    // up+全心服务理赔
    getUpServerList() {
      api.insService({ instype_id: 10 }).then((res) => {
        this.upServerList = res.data;
      });
    },
    getAndroidServeList() {
      api.insService({ instype_id: 2 }).then((res) => {
        this.androidServeList = res.data;
      });
    },
    getPhotoResubmissionTypeList() {
      api.getRelationType().then((res) => {
        this.photoResubmissionTypeList = res.data;
      });
    },
    getLogisticsList() {
      api.getLogisticsList().then((res) => {
        this.logisticsList = res.data;
      });
    },
    selectChange(relate, hx) {
      // console.log(this.form.exa_status);
      // console.log(this.form.fail_exp);
      console.log(this.type);
      if (relate) {
        console.log(213213132);
        if (relate === "model") {
          this.form.model_id = "";
          this.getModelList(this.form.brand_id);
        } else if (relate === "dateRange" || relate === "dateRange2") {
          console.log(123231321);
          if (this.form.exa_status === 1) {
            this.form.add_time = this.form.add_time.replace(/-/g, "/");
            let startTime, endTime;
            // 讯捷PG延长保Plus+ 特殊处理
            if (this.cardIddata.card&&this.cardIddata.card.card_id=== 102) {
              startTime = dateFormat(
                "YYYY-mm-dd",
                new Date(this.form.add_time)
              ).replace(/(\d{4})(-\d{2}-\d{2})/, () => {
                return parseInt(RegExp.$1)+ 0 + RegExp.$2;
              });
              endTime = dateFormat(
                "YYYY-mm-dd",
                new Date(this.form.add_time)
              ).replace(/(\d{4})(-\d{2}-\d{2})/, () => {
                return parseInt(RegExp.$1) + 2 + RegExp.$2;
              });
            } else {
              if (this.formData.order.valid_stage === "次年") {
                startTime = dateFormat(
                  "YYYY-mm-dd",
                  new Date(this.form.add_time)
                ).replace(/(\d{4})(-\d{2}-\d{2})/, () => {
                  return parseInt(RegExp.$1) + 1 + RegExp.$2;
                });
                endTime = dateFormat(
                  "YYYY-mm-dd",
                  new Date(this.form.add_time)
                ).replace(/(\d{4})(-\d{2}-\d{2})/, () => {
                  return parseInt(RegExp.$1) + 2 + RegExp.$2;
                });
              } else {
                let year = parseInt(this.formData.order.valid_stage);
                startTime = dateFormat(
                  "YYYY-mm-dd",
                  new Date(
                    new Date(this.form.add_time).getTime() +
                      1000 * 60 * 60 * 24 * 15
                  )
                );
                endTime = dateFormat(
                  "YYYY-mm-dd",
                  new Date(this.form.add_time)
                ).replace(/(\d{4})(-\d{2}-\d{2})/, () => {
                  return parseInt(RegExp.$1) + year + RegExp.$2;
                });
              }
            }
            this.temp["dateRange_term_time"] = [startTime, endTime];
            this.form.term_time_start = startTime;
            this.form.term_time_end = endTime;
          } else {
            this.form.fail_exp = "";
            this.temp["dateRange_term_time"] = [];
            this.form.term_time_start = "";
            this.form.term_time_end = "";
          }
          this.$forceUpdate();
        }
        if (relate === "dateRange2") {
          if (this.form.exa_status === 1) {
            this.form.add_time = this.form.add_time.replace(/-/g, "/");
            let startTime, endTime;
            if (this.formData.order.vice_valid_stage === "次年") {
              startTime = dateFormat(
                "YYYY-mm-dd",
                new Date(this.form.add_time)
              ).replace(/(\d{4})(-\d{2}-\d{2})/, () => {
                return parseInt(RegExp.$1) + 1 + RegExp.$2;
              });
              endTime = dateFormat(
                "YYYY-mm-dd",
                new Date(this.form.add_time)
              ).replace(/(\d{4})(-\d{2}-\d{2})/, () => {
                return parseInt(RegExp.$1) + 2 + RegExp.$2;
              });
            } else {
              let year = parseInt(this.formData.order.vice_valid_stage);
              startTime = dateFormat(
                "YYYY-mm-dd",
                new Date(
                  new Date(this.form.add_time).getTime() +
                    1000 * 60 * 60 * 24 * 15
                )
              );
              endTime = dateFormat(
                "YYYY-mm-dd",
                new Date(this.form.add_time)
              ).replace(/(\d{4})(-\d{2}-\d{2})/, () => {
                return parseInt(RegExp.$1) + year + RegExp.$2;
              });
            }
            this.temp["dateRange_term_time2"] = [startTime, endTime];
            this.form.term_time_start2 = startTime;
            this.form.term_time_end2 = endTime;
          } else {
            this.temp["dateRange_term_time2"] = [];
            this.form.term_time_start2 = "";
            this.form.term_time_end2 = "";
          }
          this.$forceUpdate();
        }
      }
      console.log("hx");
      if (hx === "hx") {
        console.log(123);
        if (this.form.exa_status === 1) {
          if (this.type == 1 || this.type == 4) {
            this.form.fail_exp =
              "碎屏险注册成功，服务自注册成功当天15日后生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交碎屏险理赔申请";
          } else if (
            [35, 5, 36, 37, 40].findIndex((v) => v == this.type) >= 0
          ) {
            this.form.fail_exp =
              "本服务15日后生效，您可享受的各项权益详见实体卡片，如要维修或者换机，请至公众号或者门店做理赔申请，任何疑问您可致电400-1818-819咨询。";
          } else if (this.type == 24) {
            this.form.fail_exp =
              "安卓智选保障计划注册成功，服务自注册成功当天30日后生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交理赔申请";
          } else if (this.type == 26) {
            this.form.fail_exp =
              "苹果智选保障计划注册成功，服务自注册成功当天30日后生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交理赔申请";
          } else if (this.type == 20) {
            this.form.fail_exp =
              "苹果智选保障计划注册成功，服务自注册成功当天15日后生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交理赔申请";
          } else if (this.type == 19) {
            this.form.fail_exp =
              "安卓智选保障计划注册成功，服务自注册成功当天15日后生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交理赔申请";
          } else if (this.type == 28 || this.type == 29) {
            this.form.fail_exp =
              "延保注册成功，服务自注册成功后次年生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交延保理赔申请";
          } else if (this.type == 33) {
            this.form.fail_exp =
              "电池保注册成功，服务自注册成功后次年生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交电池保理赔申请";
          } else if (this.type == 38) {
            this.form.fail_exp =
              "后盾险注册成功，服务自注册成功当天15日后生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交后盾险理赔申请";
          } else if (this.type == 42) {
            this.form.fail_exp =
              "整机维修保障计划注册成功，服务自注册成功当天30日后生效，服务期内出现进液、进水，私拆、私修情况均不在理赔范围之内，需维修时请在公众平台提交理赔申请";
          }
        } else {
          this.form.fail_exp = "";
        }
      }
    },
    saveOriginalImage(prop, objName) {
      api
        .retainOldPic({ mold: this.type, order_id: this.orderId, key: prop })
        .then((res) => {
          this.formData[objName]["old" + prop.replace("tem", "")] =
            res.data.url;
          this.$message.success(res.msg || "保存成功");
        })
        .catch((err) => {});
    },
    refund() {
      this.$confirm("是否发起退款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        wxRefund({ order_id: this.formData.order.order_id }).then((res) => {
          this.$message.success("退款成功！");
          this.reset();
        });
      });
    },
    dateRangeChange(range, item) {
      this.form[item.props[0]] = range[0];
      this.form[item.props[1]] = range[1];
    },
    reset() {
      this.getOrder();
    },
    viewOrder(type) {
      let { order } = this.formData;
      let params;
      if (type === "register") {
        params = { type: order.order_mold, orderId: order.order_id };
      } else if (type === "claim") {
        params = { type: order.claims_mold, orderId: order.claims_id };
      }
      const { href } = this.$router.resolve({
        name: "订单详情",
        params,
      });
      window.open(href, "_blank");
    },
    confirm() {
      this.logisticsList.forEach((item) => {
        if (item.link == this.form.link) {
          this.form.link_id = item.id;
        }
      });
      let form = JSON.parse(JSON.stringify(this.form));
      form.mold = this.type;
      form.order_id = this.orderId;

      if (Array.isArray(form.damage_title)) {
        let strList = [];
        form.damage_title.forEach((item) => {
          let findResult = this.phoneScreenStatusList.find((p) => {
            return p.damage_id === item;
          });
          if (findResult) {
            strList.push(findResult.damage_title);
          }
        });
        form.damage_title = strList.join(",");
      }
      this.submitShow = true;
      api
        .orderExamine(form)
        .then((res) => {
          this.$message.success(res.msg || "审核成功");
          this.submitShow = false;
        })
        .catch((err) => {
          this.submitShow = false;
        });
    },
    sendMessageToWX(row) {
      api
        .sendWxPush({ mold: this.type, order_id: this.orderId })
        .then((res) => {
          this.$message.success(res.msg || "推送成功");
        })
        .catch((err) => {});
    },
    queryActiveTime() {
      if (this.imeiData) {
        return;
      }
      api
        .getPhoneMode({
          order_id: this.formData.order.order_id,
        })
        .then((res) => {
          this.imeiData = res.data;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.rong {
  display: inline-block;
  background-color: #f2f9f0;
  color: #80c27f;
  padding: 0 6px;
  border-radius: 6px;
  line-height: 30px;
}

.el-input,
.el-select,
.el-date-picker,
.el-textarea,
.el-date-editor,
::v-deep .el-input__inner {
  width: 500px !important;
}

.preview-img {
  width: 148px;
  height: 148px;
  border-radius: 5px;
}

.head-img {
  width: 50px;
  height: 50px;
}

.img-operation {
  display: flex;
  line-height: 1em;
  align-items: flex-end;

  .notice {
    color: red;
    font-size: 12px;
  }

  .el-button {
    margin-right: 10px;
  }
}

::v-deep .el-upload {
  margin-bottom: 15px;
}

.bottom-btns {
  margin-left: 160px;
}

.picc-callback {
  background: #f5f6fa;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  .item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .name {
      color: #999;
    }

    .value {
      color: #666;

      .el-button {
        border-radius: 50px;
      }
    }
  }

  .item:nth-child(2n-1) {
    width: 33.3%;
  }

  .item:nth-child(2n) {
    width: 66.6%;
  }
}

.warning span {
  color: red;
  font-size: 18px;
  font-weight: bold;
}

.append {
  margin-left: 20px;

  .el-button:hover {
    color: red;
  }
}

.original-pic {
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;

  .mark {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(15, 139, 245, 0.5);
    color: #fff;
    border-bottom-left-radius: 10px;
    padding: 0 10px;
    line-height: 26px;
  }
}

::v-deep .watermark {
  position: absolute;
  left: 220px;
  max-width: 400px;
  line-height: 1.5em;
  word-break: break-all;
  top: 60px;
  color: #fff;
  background: #00000080;
  border-radius: 10px;
  padding: 15px;
}

.detail {
  position: relative;
}

.other-info {
  display: flex;
  position: absolute;
  left: 750px;
  top: 170px;

  .sub-insurance,
  .photo-resubmission {
    width: 300px;

    .title {
      font-weight: bold;
      position: relative;

      &:before {
        content: "";
        display: block;
        width: 4px;
        height: 15px;
        background: #0981ff;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
      }

      .total-cost {
        font-weight: normal;
        font-size: 14px;
        margin-left: 20px;
        color: red;
      }
    }
  }
}
</style>
